import React from "react"
import Helmet from "react-helmet"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Navbar from "../components/navbar"
import Layout from "../components/layout"
import "./software.scss"
import SEO from "../components/seo"
import { Link } from "gatsby"
import ListGroup from 'react-bootstrap/ListGroup'

const PrepareGroupTwoPage = () => (
    <Layout>
        <Helmet>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
            />
        </Helmet>
        <SEO title="2 группа - билеты для подготовки к экзамену" />

        <Navbar />
        <section className="quizzes-section-telis">
            <Container fluid>
                <Row>
                    <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="align-self-center my-5 pt-5"
                    >
                        <h1 style={{
                            color: "var(--textNormal)",
                            wordBreak: "break-all",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                        }} className="font-weight-bold text-center mb-0">
                            Билеты для подготовки для 2 группы
        </h1>
                        <h2 className="pt-5 w-90 text-center mx-auto" style={{
                            color: "var(--textNormal)",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                            maxWidth: "1140px",
                        }}>В данном разделе собраны билеты для подготовки к экзамену для 2 группы. Вы можете выбрать необходимое количество тестов для прохождения, прежде чем перейти к экзамену.</h2>
                        <Container>
                            
                            <h2 className="text-center mt-5">Билеты</h2>
                            <div className="d-grid text-center my-2">
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/xd3uR6Xkzs3Kyd3J6" target="_blank"><div className="border border-info py-4 my-3">Билет 1</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/rxtuxRDBjznm1xvQ6" target="_blank"><div className="border border-info py-4 my-3">Билет 2</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/ackPRr9gHuMWFKhK6" target="_blank"><div className="border border-info py-4 my-3">Билет 3</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/9uLhTnyjd5TEExdc6" target="_blank"><div className="border border-info py-4 my-3">Билет 4</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/CB58TMKPHGu79FkG6" target="_blank"><div className="border border-info py-4 my-3">Билет 5</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/GdRoQXbSGca53unx8" target="_blank"><div className="border border-info py-4 my-3">Билет 6</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/P1UeHeSW23PQLG7X6" target="_blank"><div className="border border-info py-4 my-3">Билет 7</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/LFfZiTy7AJG7b8Si9" target="_blank"><div className="border border-info py-4 my-3">Билет 8</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/mg7vt2G6v2gt7fYZ9" target="_blank"><div className="border border-info py-4 my-3">Билет 9</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/bsPtgvH8tjiPT3k67" target="_blank"><div className="border border-info py-4 my-3">Билет 10</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/QUGNcNFqiqZfNVbJ7" target="_blank"><div className="border border-info py-4 my-3">Билет 11</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/NKD1oMysL7XU613YA" target="_blank"><div className="border border-info py-4 my-3">Билет 12</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/YVU7212nJtLJYJkBA" target="_blank"><div className="border border-info py-4 my-3">Билет 13</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/dziEYCwKZCmwMoaz6" target="_blank"><div className="border border-info py-4 my-3">Билет 14</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/NSrZCJGBJeeEJAoL9" target="_blank"><div className="border border-info py-4 my-3">Билет 15</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/B6WFbqtNGzWoC7EZ9" target="_blank"><div className="border border-info py-4 my-3">Билет 16</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/aLiH9iZ2zQ67nkxP8" target="_blank"><div className="border border-info py-4 my-3">Билет 17</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/BLU5ovjcars5Gvs99" target="_blank"><div className="border border-info py-4 my-3">Билет 18</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/1zs9SpxCa6cdXj4z9" target="_blank"><div className="border border-info py-4 my-3">Билет 19</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/cUmBSZTeTkTfbo7B8" target="_blank"><div className="border border-info py-4 my-3">Билет 20</div></a>
                            </div>
                            
                        </Container>

                    </Col>

                </Row>
            </Container>
        </section>
    </Layout>
)

export default PrepareGroupTwoPage
